import Image from "next/legacy/image";
import Link from 'next/link';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import dynamic from "next/dynamic";
const NotFoundSection = dynamic(() => import('../../NotFoundSection'))
// Cat Images
import art from '../../../public/category/art-nft-icon.webp'
import collectibles from '../../../public/category/collectibles.webp'
import domain from '../../../public/category/domain.webp'
import games from '../../../public/category/games.webp'
import memes from '../../../public/category/memes.webp'
import music from '../../../public/category/music.webp'
import photography from '../../../public/category/photography.webp'
import sports from '../../../public/category/sports.webp'
import trading_card from '../../../public/category/trading_card.webp'
import others from '../../../public/category/others.webp'
import videos from '../../../public/category/videos.webp'

import {useTranslation} from 'next-i18next';
// styles
import styles from "./BrowseCat.module.scss";

const BrowseCategory = (props) => {

    const { t } = useTranslation('common');

    const getImageFromItem = (item) => {
        let imageSrc = others;
        if (item._id === "625a4a38dc9e3588e3211e67") {
            imageSrc = art;
        } else if (item._id === "625a4a66dc9e3588e3211e6b") {
            imageSrc = music;
        } else if (item._id === "625a4a6cdc9e3588e3211e6d") {
            imageSrc = sports;
        } else if (item._id === "627a2ae7550d7001c551c977") {
            imageSrc = videos;
        } else if (item._id === "627a2af0a99603bc742a6016") {
            imageSrc = domain;
        } else if (item._id === "627a2b0aa99603bc742a6018") {
            imageSrc = photography;
        } else if (item._id === "627a2b12a99603bc742a601e") {
            imageSrc = memes;
        } else if (item._id === "627a2b17550d7001c551c986") {
            imageSrc = games;
        } else if (item._id === "627a2b2eca0308869ffcb320") {
            imageSrc = collectibles;
        } else if (item._id === "627a2b36ca0308869ffcb322") {
            imageSrc = trading_card;
        } else if (item._id === "628c9b2b0e9064f2749f96a2") {
            imageSrc = others;
        }
        return <Image src={imageSrc}
                   alt={item.category_name}
                   width="56" height="56"
                   layout='responsive'
                   loading='lazy'/>
    }

    const { categoryList } = props;

    return (
        <div className={`section ${styles.browseCatSec}`}>
            <Container>
                <h2 className={`h3 ${styles.h3}`}>{t('common:browse_category')}</h2>
                <Row className={styles.list}>
                    {categoryList?.length > 0 ? categoryList.map((item, idx) => (
                        <Col sm={6} md={4} xl={3} key={idx} className={styles.boxList}>
                            <Link href={`/nft/category/${encodeURI(item.page_name)}`} key={idx}
                                  className={styles.browseCat}>
                                <div className="d-flex align-items-center">
                                    <div className={styles.category_img}>
                                        {getImageFromItem(item)}
                                    </div>
                                    <span className={`ms-3 ${styles.category_text}`}>
                                        {
                                            props.currLocale === "en" ? item.category_name.charAt(0).toUpperCase() + item.category_name.slice(1) : item.category_name_arabic
                                        }
                                    </span>
                                </div>
                            </Link>
                        </Col>
                    )) : <NotFoundSection/>
                    }
                </Row>
            </Container>
        </div>
    )

}
export default BrowseCategory;
